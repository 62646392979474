import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Search = styled('div')(({ theme }) => ({
  
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));


export default function Header() {
  const appMode = useSelector((state) => state.mode.mode);
  const notifications = useSelector((state) => state.notifications.notifications);

  const dispatch = useDispatch();

  
 

  const toggleSidebar = () => {
    const event = new Event("toggleSideBar");
    window.dispatchEvent(event);
  }

  return (
    <Box  sx={{ flexGrow: 1, position: 'fixed', zIndex: 1500, width: '100%' }}>
      <AppBar position="static" color='custom' enableColorOnDark={false}>
        <Toolbar>
          {appMode === 'dark' ? 
          <img style={{height: "40px"}} alt="Menubar Icon" src="https://res.cloudinary.com/dpg4bmkzc/image/upload/v1721438889/fortunaassets-bold-logo_irjxxc.png" />

          :
          <img style={{height: "40px"}} alt="Menubar Icon" src="https://res.cloudinary.com/dpg4bmkzc/image/upload/v1721438889/fortunaassets-bold-logo_irjxxc.png" />

          }
         
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
            

            <IconButton
              component={RouterLink} to="/notifications"
              size="large"
              aria-label="show notofocations"
              color="inherit"
            >
              <Badge badgeContent={notifications.length} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            
          </Box>
          <IconButton
            onClick={toggleSidebar}
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ ml: 2, display: {md: 'none'} }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}